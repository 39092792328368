<template>
  <main>
    <client-header v-if="view_client_profile != true"></client-header>
    <section class="mt-8" v-if="view_client_profile != true">
      <section class="flex justify-between items-center">
        <section>
          <h1 class="text-xl font-medium">Clients</h1>
        </section>
      </section>
    </section>
    <section>
      <all-client @view-client-profile="viewProfile"></all-client>
    </section>
  </main>
</template>

<script>
import AllClient from './view/AllClient.vue'
import ClientHeader from './ClientHeader.vue'
export default {
  components: { AllClient, ClientHeader },
  data() {
    return {
      view_client_profile: false
    }
  },
  watch: {},
  methods: {
    viewProfile(val) {
      this.view_client_profile = val
    }
  }
}
</script>

<style scoped>
.card:hover {
  @apply bg-gray-50;
  @apply opacity-90;
  @apply transition;
  @apply duration-300;
  @apply ease-in-out;
}
</style>
