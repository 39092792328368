<template>
  <main>
    <section class="mt-6">
      <section
        v-if="view_client_profile != true"
        class="
          flex
          sm:w-98
          w-full
          bg-white
          shadow-sm
          rounded
          px-4
          py-1
          items-center
          align-middle
        "
      >
        <input
          type="search"
          name=""
          id=""
          class="w-full py-1 outline-none text-lg"
          placeholder="Search clients"
          v-model="client_search"
        />
        <search-icon class="ml-3" :width="'w-5'" :height="'h-5'"></search-icon>
      </section>
      <section class="mt-10">
        <section
          class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-6"
          v-if="view_client_profile != true"
        >
          <div
            class="
              shadow
              bg-white
              py-12
              px-10
              flex flex-col
              justify-center
              text-center
              items-center
              relative
              card
            "
            v-for="(detail, index) in new_clients"
            :key="index"
          >
            <div class="">
              <user-icon :width="'w-20'" :height="'h-20'"></user-icon>
            </div>
            <h1 class="text-lg font-medium leading-8 capitalize">
              {{ detail.client_details ? detail.client_details.name : '' }}
            </h1>
            <small class="text-base leading-7 text-gray-500 text-opacity-80">{{
              detail.client_details ? detail.client_details.bus_name : ''
            }}</small>
            <div
              v-if="detail.isActive != false"
              class="
                bg-active_pill_color
                px-2
                py-1
                rounded-3xl
                text-sm
                font-medium
              "
            >
              pet has report
            </div>
            <div class="absolute top-0 h-1/5 w-full card-top-bar">
              <div class="flex justify-between px-4 py-3">
                <a
                  href="javascript:;"
                  class="text-gray-400 cursor-pointer hover:text-primary"
                  @click="editProfile(index)"
                >
                  Edit
                </a>
                <a
                  href="javascript:;"
                  class="text-gray-400 cursor-pointer hover:text-primary"
                  @click="viewProfile(detail.id)"
                >
                  view
                </a>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
    <section>
      <edit-client-modal
        v-if="edit_client_modal == true"
        :client_detail="edit_client"
        :client_id="client_id"
        @close-edit-modal="editProfile"
      ></edit-client-modal>
    </section>
    <section>
      <loading-model v-if="loading"></loading-model>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import UserIcon from '../../Icons/userIcon.vue'
import EditClientModal from '../../Edit/EditClientModal.vue'
import LoadingModel from '../../Utilities/loadingModel.vue'
import SearchIcon from '../../Icons/searchIcon.vue'
export default {
  components: { EditClientModal, UserIcon, LoadingModel, SearchIcon },
  data() {
    return {
      client_id: null,
      edit_client_modal: false,
      edit_client: null,
      view_client: null,
      view_client_profile: false,
      new_clients: [],
      client_search: null
    }
  },
  created() {},
  computed: mapState('clients', ['clients', 'loading']),
  watch: {
    client_search(val) {
      this.new_clients = []
      this.searchClient(val)
    },

    clients() {
      this.new_clients = this.clients
    }
  },
  methods: {
    searchClient(val) {
      this.clients.forEach(info => {
        var name = info.client_details.name.toLowerCase()
        var lowercase_val = val.toLowerCase()
        if (name.includes(lowercase_val)) {
          this.new_clients.push(info)
        }
      })
    },
    editProfile(val) {
      this.edit_client = this.clients[val]
      this.edit_client_modal = !this.edit_client_modal
    },
    viewProfile(val) {
      this.$router.push({
        name: 'View',
        params: { id: val }
      })
    }
  },
  mounted() {
    this.$store.dispatch('clients/getClient')

    if (this.clients != undefined) {
      this.new_clients = this.clients
    }
  }
}
</script>

<style scoped>
.card:hover {
  @apply bg-gray-50;
  @apply opacity-90;
  @apply transition;
  @apply duration-300;
  @apply ease-in-out;
}
</style>
