<template>
  <header>
    <section
      class="mb-10 sm:flex justify-end items-center sm:space-y-0 space-y-4"
    >
      <router-link
        class="bg-primary block rounded shadow text-white px-10 py-2"
        to="/reports"
        >Create new report</router-link
      >
    </section>
    <section
      class="
        grid grid-cols-1
        md:grid-cols-3
        gap-x-8
        lg:gap-x-12
        gap-y-6
        lg:gap-y-7
      "
    >
      <div
        class="
          bg-light_blue
          w-full
          pt-7
          pb-7
          px-8
          md:px-6
          lg:px-8
          h-full
          space-y-2
          rounded
          shadow
        "
      >
        <div class="flex justify-between">
          <div class="mt-10 space-y-4">
            <h3 class="text-white text-3xl">
              {{ client_count }} <span>client</span>
              <span v-if="client_count > 1">clients</span>
            </h3>
            <p class="text-white text-lg opacity-50">
              {{ client_count }} active
            </p>
          </div>
          <div>
            <img src="/img/user.png" alt="user profile" class="w-20 h-24" />
          </div>
        </div>
      </div>
      <!-- <div
        class="bg-primary w-full pt-7 pb-7 px-8 h-full space-y-2 rounded shadow"
      >
        <div class="flex justify-between">
          <div class="mt-2 space-y-5">
            <div>
              <p class="text-white">Total transactions</p>
              <div class="text-white text-3xl">
                2,330,000 <small class="text-base">NGN</small>
              </div>
            </div>
            <p class="text-white text-lg opacity-50">87 transactions</p>
          </div>
          <div>
            <img src="/img/recycle.png" alt="user profile" class="w-24 h-24" />
          </div>
        </div>
      </div>
      <div class="w-full bg-light_blue shadow pt-7 pb-7 pl-8 pr-5 md:pb-0">
        <div class="flex justify-between">
          <div class="mt-2 space-y-5">
            <div>
              <p class="text-white">Debt</p>
              <div class="text-white text-3xl">
                560,000 <small class="text-base">NGN</small>
              </div>
            </div>
          </div>
          <div>
            <img src="/img/barchart.png" alt="user profile" class="w-24 h-24" />
          </div>
        </div>
        <div class="flex justify-between">
          <p class="text-white text-lg opacity-50">87 transactions</p>
          <router-link
            class="px-2 py-1 font-medium flex rounded-sm text-white"
            to="/"
          >
            <p>View details</p>
            <arrow-icon class="ml-4"></arrow-icon>
          </router-link>
        </div>
      </div> -->
    </section>
  </header>
</template>

<script>
import { mapState } from 'vuex'
// import ArrowIcon from '../Icons/arrowIcon.vue'
export default {
  components: {
    /* ArrowIcon */
  },
  created() {
    this.$store.dispatch('clients/clientCount')
  },
  computed: mapState('clients', ['client_count'])
}
</script>

<style></style>
