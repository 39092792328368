<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 md:w-2/3 w-full mx-auto z-30">
          <section
            class="px-4 py-6 bg-white mx-2 sm:mx-8 shadow rounded sm:p-10"
          >
            <div
              @click="$emit('close-edit-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>
            <section class="space-y-1 sm:mt-2 mt-2">
              <h2 class="leading-relaxed text-xl text-left sm:w-1/3">
                Edit Profile
              </h2>
            </section>
            <section
              class="
                grid grid-cols-1
                xl:grid-cols-3 xl:gap-x-16
                gap-y-10
                sm:px-16
                md:px-16
                px-0
                py-14
              "
            >
              <section class="col-span-1 place-self-center">
                <div class="flex justify-center">
                  <user-icon :height="'h-36'" :width="'w-36'"></user-icon>
                </div>

                <div class="items-center text-center mt-3 sm:mt-3">
                  <p class="text-gray-500 text-opacity-80 text-base">
                    Client ID
                  </p>
                  <p class="text-lg font-semibold">{{ client.id }}</p>
                </div>
              </section>
              <section class="col-span-2">
                <section class="grid grid-cols-1 gap-y-6">
                  <div class="">
                    <input-text
                      placeholder="Full Name"
                      required
                      v-model="client.name"
                    ></input-text>
                  </div>
                  <div class="">
                    <input-text
                      placeholder="Business Name"
                      required
                      v-model="client.bus_name"
                    ></input-text>
                  </div>
                  <div class="">
                    <input-text
                      type="email"
                      placeholder="Email"
                      required
                      v-model="client.email"
                    ></input-text>
                  </div>
                  <div class="">
                    <input-text
                      placeholder="Phone number"
                      required
                      v-model="client.phone"
                    ></input-text>
                  </div>
                  <div class="">
                    <input-text
                      placeholder="Address"
                      required
                      v-model="client.address"
                    ></input-text>
                  </div>
                </section>
                <section class="mt-8 sm:mt-16 flex justify-center md:block">
                  <div class="mx-auto sm:mx-0">
                    <button
                      class="block py-3 px-16 text-white bg-primary rounded-md"
                      @click="submitEdit"
                    >
                      Save
                    </button>
                  </div>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import closeIcon from '../Icons/closeIcon.vue'
import InputText from '../Utilities/InputText.vue'
import UserIcon from '../Icons/userIcon.vue'
export default {
  components: { closeIcon, InputText, UserIcon },
  props: {
    client_detail: Object
  },
  data() {
    return {
      client: {
        id: this.client_detail.id,
        name: this.client_detail.client_details.name,
        bus_name: this.client_detail.client_details.bus_name,
        email: this.client_detail.client_details.email,
        phone: this.client_detail.client_details.phone,
        address: this.client_detail.client_details.address
      }
    }
  },
  methods: {
    submitEdit() {
      var updateClient = {
        id: this.client.id,
        client: this.client,
        updatedAt: Date.now()
      }
      Swal.fire({
        title: 'Do you want to save the changes?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Save`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('clients/updateClient', updateClient)

          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            // timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          })
            .then(result => {
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'saved successfully',
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            })
            .then(() => {
              this.$emit('close-edit-modal')
            })
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  }
}
</script>

<style scoped>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
